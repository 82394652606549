<template>
  <div class="height1">
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search"> </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="订单编号" @keyup.enter.native="SearchInputKeydown" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe height="calc(100% - 90px)" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }">
          <el-table-column prop="orderNum" label="订单编号" width="240px">
            <template slot-scope="scope">
              <!-- <el-link target="_blank" @click="toDetail(scope.row)">{{ scope.row.orderNum }}</el-link> -->
              <p>{{ scope.row.orderNum }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" width="150" label="创建时间" align="center">
            <template slot-scope="scope">{{ scope.row.createTime | timefilters }}</template>
          </el-table-column>
          <el-table-column prop="afterVersionName" label="版本" align="center"> </el-table-column>
          <el-table-column prop="payAmount" label="金额（元）" align="center"> </el-table-column>
          <el-table-column prop="days" label="续费时长" align="center"> </el-table-column>
          <el-table-column prop="orderState" label="订单状态" align="center">
            <template slot-scope="scope">
              <el-tag type="warning" v-if="scope.row.orderState == '0'">待支付</el-tag>
              <el-tag type="success" v-if="scope.row.orderState == '1'">已支付</el-tag>
              <el-tag type="info" v-if="scope.row.orderState == '-1'">取消支付</el-tag>
              <el-tag type="warning" v-if="scope.row.orderState == '2'">待审核</el-tag>
              <el-tag type="warning" v-if="scope.row.orderState == '3'">议价待审核</el-tag>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
              <span class="warning optionBtn" v-if="scope.row.orderState == '0' || scope.row.orderState == '2'" @click="CancelBtn(scope.row)">取消订单</span>
              <!-- <span class="success optionBtn" v-if="scope.row.payWay == 1 && scope.row.orderState == '0'" plain
                                @click="RechargeSave(scope.row)">继续支付</span> -->
              <!--<span size="mini" type="primary" v-if="scope.row.orderState == '0'" @click="EditBtn(scope.row)" plain>修改订单</span>-->
              <!-- <span class="danger optionBtn" @click="deleteOrder(scope.row)" v-if="scope.row.orderState == '1' || scope.row.orderState == '-1'">删除</span> -->
              <span class="primaryDis optionBtn" v-if="scope.row.payWay == 3 && scope.row.orderState == '2'"> 平台审核中</span>
              <span class="success optionBtn" @click="seemychargeSave(scope.row)">明细</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="StaffTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import bread from '../../../components/common/breadcrumb'
import { RenewalUpgrade } from '../../../components/HospitalDomain/RenewalUpgrade'
import { WeChatPay } from '../../../components/HospitalDomain/WeChatPay'
import { Distributor } from '../../../components/HospitalDomain/Distributor'
import { Invoice } from '../../../components/HospitalDomain/Invoice'
var timer = ''
export default {
  name: 'Order',
  components: {
    bread,
  },
  data() {
    var renewalUpgrade = new RenewalUpgrade(this.TokenClient, this.Services.Authorization)
    var distributor = new Distributor(this.TokenClient, this.Services.Authorization)
    var weChat = new WeChatPay(this.TokenClient, this.Services.Authorization)
    var invoice = new Invoice(this.TokenClient, this.Services.Authorization)
    return {
      RenewalDomain: renewalUpgrade,
      DistributorDomain: distributor,
      WeChatPayDomain: weChat,
      Invoice: invoice,
      tableData: [],
      searchForm: {
        statrTime: '',
        endTime: '',
        keyWord: '',
        datepicker: '',
      },
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      StaffTotal: 0,
      organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
      userId: JSON.parse(sessionStorage.getItem('userInfo')).id,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      invoiceDialog: false,
      formLabelWidth: '120px',
      invoiceForm: {
        invoiceType: 0,
      },
      size: '',
      payMoney: 0,
      PaymentDialog: false,
      qRcodeUrl: '',
      gettime: '',
      weChatInfo: {},
      InvoiceData: [],
      invoiceDetail: {},
    }
  },
  mounted() {
    this.GetList()
  },
  methods: {
    seemychargeSave(item) {
      this.$router.push({
        path: '/Finance/myOrderDetail',
        query: {
          orderId: item.id,
        },
      })
    },
    SearchInputKeydown(event) {
      if (event.keyCode == 13) {
        this.PageIndex = 1
        this.GetList()
      }
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.GetList()
    },
    search() {
      this.PageIndex = 1
      this.GetList()
    },
    toDetail(item) {
      this.$router.push({
        path: '/Finance/OrderDetail',
        query: {
          orderId: item.id,
        },
      })
    },
    GetList() {
      var _this = this
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.tableData = []
      _this.RenewalDomain.OrderList(item.startTime, item.endTime, _this.PageIndex, item.keyWord,
        function (data) {
          _this.tableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.StaffTotal = data.data.dataTotal
        },
        function (error) {
          console.log(error)
        }
      )
    },
  },
}
</script>

<style scoped>
.pay-top {
  position: relative;
}

.payItem {
  line-height: 2;
}

.payItem p {
  float: left;
}

.payItem span {
  float: right;
}

.payNum {
  color: red;
  font-size: 24px;
}

.pay-bg {
  position: relative;
  margin: 20px auto;
  width: 350px;
  height: 436px;
  background: url('../../../assets/img/wx_pay.jpg') top center no-repeat;
  background-size: cover;
}

.pay-bg img {
  position: absolute;
  width: 160px;
  top: 140px;
  left: 100px;
}
</style>
